import { useInputControl } from '@conform-to/react'
import { format, formatISO, toDate } from 'date-fns'
import { REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps } from 'input-otp'
import { CalendarIcon } from 'lucide-react'
import React, { useId } from 'react'
import { Button } from '#app/components//ui/button.tsx'
import { Calendar } from '#app/components//ui/calendar.tsx'
import { Checkbox, type CheckboxProps } from '#app/components//ui/checkbox.tsx'

import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '#app/components//ui/input-otp.tsx'
import { Input } from '#app/components//ui/input.tsx'
import { Label } from '#app/components//ui/label.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '#app/components//ui/popover.tsx'
import { Select } from '#app/components//ui/select.tsx'
import { Textarea } from '#app/components//ui/textarea.tsx'
import { cn } from '#app/utils/misc.js'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
				className={errorId? "border-red-500":""}
			/>
			{(
				<div className="pb-2 pt-1 text-red-700">
					{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
				</div>
			)}
		</div>
	)
}

export function OTPField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Partial<OTPInputProps & { render: never }>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
	helperText,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
	helperText?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2 items-center">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-sm"
				/>
			</div>
			{helperText ? (
				<p className="text-sm text-muted-foreground">{helperText}</p>
			) : null}
			<div className="px-4 pt-1 pb-3">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function DateField({
	labelProps,
	errors,
	inputProps,
	className,
	buttonClassName
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	errors?: ListOfErrors
	className?: string
	buttonClassName?: string
	inputProps: React.InputHTMLAttributes<HTMLInputElement> & {
		name: string
		form: string
		value?: string
	}
}) {
	const { defaultValue, id } = inputProps

	const input = useInputControl({
		name: inputProps.name,
		formId: inputProps.form,
		initialValue: defaultValue ? (defaultValue as string) : undefined,
	})

	const selectedDate = toDate(input.value as string)

	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={cn("flex flex-col gap-2", className)}>
			<Label htmlFor={id} {...labelProps} />

			<Popover>
				<PopoverTrigger asChild>
					<Button
						variant={'outline'}
						className={cn(
							'w-[280px] justify-start text-left font-normal',
							!selectedDate && 'text-muted-foreground',
							buttonClassName
						)}
						disabled={inputProps.disabled}
					>
						<CalendarIcon className="w-4 h-4 mr-2" />
						{selectedDate ? (
							format(selectedDate, 'PPP')
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0">
					<Calendar
						mode="single"
						selected={selectedDate}
						defaultMonth={selectedDate}
						onSelect={date => {
							input.change(formatISO(date as Date))
						}}
						initialFocus
					/>
				</PopoverContent>
			</Popover>
			<div className="px-4 pt-1 pb-3">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	errors,
	className,
	children,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: {
		name: string
		value?: string
		form: string
		defaultValue?: string
		placeholder?: string
	}
	errors?: ListOfErrors
	className?: string
	children: React.ReactNode
}) {
	const fallbackId = useId()
	const id = selectProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	const input = useInputControl({
		name: selectProps.name,
		formId: selectProps.form,
		initialValue: selectProps.defaultValue
			? selectProps.defaultValue
			: undefined,
	})
	return (
		<div className={cn(className, errorId ? 'text-red-500': '')}>
			<Label htmlFor={id} {...labelProps} />
			<Select
				name={id}
				value={input.value}
				onValueChange={value => input.change(value)}
			>
				{children}
			</Select>
			{errorId ? (
				<div className="min-h-[32px] px-4 pb-3 pt-1 text-red-500">
					{errorId ? 
					<>
					<ErrorList id={errorId} errors={errors} />
					</>
					 : null}
				</div>
			) : null}
		</div>
	)
}
